
import {
  checkObjectForFalsyValues,
  hasValidAddress,
  objectDifference
} from "@/helpers";
import {
  billingChangeRequestMapActions,
  billingChangeRequestMapGetters,
  billingChangeRequestMapMutations,
  billingChangeRequestMapState
} from "@/store/modules/billingchangerequest";
import * as types from "@/store/mutation-types";
import { IAddress } from "@/types";
import { cloneDeep, get } from "lodash";
import { omit } from "lodash";
import { propertyChangeRequestMapState } from "@/store/modules/propertychangerequest";
import insuredSchemas from "@/forms/shared/changeRequestInsuredSchema";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import { isBefore, subDays } from "date-fns";
import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";
import VueWithMixins from "@/helpers/mixins";
import ChangeRequestMixin from "../../ChangeRequests/ChangeRequestMixin";
import { defaultAddressStructure } from "@/helpers/defaultObjects";

interface IAgencyAddress {
  agencyCity: string;
  agencyZipCode: string;
  agencyState: string;
  successMessage: string;
  errorMessage: string;
  activeNames: any[];
  error: string;
  uploadedFile: string[];
  agentCertifyCheckBox: boolean;
  CancellationReasonRadioButton: string;
}

export default VueWithMixins(ChangeRequestMixin).extend({
  name: "EditBillingChangeRequest",
  mixins: [ActivitiesMixin],
  components: {
    PolicyInfo: () => import("@/components/User/PolicyInfo.vue"),
    ChangeRequestFileUpload: () =>
      import("@/components/AttachmentUploader/ChangeRequestFileUpload.vue"),
    ChangeRequestInsured: () =>
      import("@/components/User/ChangeRequestInsured.vue"),
    CustomAlert
  },
  props: {
    billingChangeRequestId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      successMessage: "",
      activeNames: ["1", "2"],
      error: "",
      uploadedFile: [],
      agentCertifyCheckBox: false,
      CancellationReasonRadioButton: "",
      insuredSchemas,
      billingRemarks: "",
      otherRemarks: "",
      isPast90Days: false,
      hasUploadedFile: false
    };
  },
  destroyed() {
    this.setState({ key: "editing", value: null });
  },
  mounted() {
    this.editingField = this.editField;
    this.$nextTick(() => {
      this.CancellationReasonRadioButton = this.editing?.data.billing?.reason
        ? this.editing?.data.billing?.reason
        : "";
      this.billingRemarks = get(this.editing, "data.billing.remarks", "");
      this.otherRemarks = get(this.editing, "data.billing.other", "");
      const effectiveDate: Date | null = get(
        this.editing,
        "data.policyInfo.effectiveDate",
        null
      );
      const ninetyDaysAgo = subDays(new Date(), 90);
      if (effectiveDate) {
        this.isPast90Days = isBefore(new Date(effectiveDate), ninetyDaysAgo);
      }
    });
  },
  methods: {
    ...billingChangeRequestMapActions([
      "deleteBillingChangeRequest",
      "updateBillingChangeRequest"
    ]),
    ...billingChangeRequestMapMutations(["SET_EDIT"]),
    ...billingChangeRequestMapMutations({
      editField: types.SET_EDIT_FIELD,
      setState: types.SET_STATE
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteCallback() {
      try {
        this.$modal.show("billingChangeRequestSingleDeleteModal");
      } catch (error) {
        this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteOneBillingChangeRequest(): Promise<void> {
      try {
        await this.deleteBillingChangeRequest(this.billingChangeRequestId);
        this.$modal.hide("billingChangeRequestSingleDeleteModal");
        this.$appNotifySuccess("Cancellation Change Request Deleted.");
        this.goBackToListView("/billingchangerequests");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      }
    },
    attachmentUploadHandler(data: any) {
      this.uploadedFile = data;
      this.doUpdateFile();
    },
    async sendData() {
      await this.updateBillingChangeRequest({
        id: this.billingChangeRequestId,
        update: this.updatedFields
      });
    },
    async Submit() {
      await this.updateBillingChangeRequest({
        id: this.billingChangeRequestId,
        update: {
          status: "Submitted",
          submittedOn: new Date(),
          ...this.updatedFields
        }
      });

      this.goBackToListView("/billingchangerequests");
      this.$appNotifySuccess("Cancellation Change Request Submitted.");
    },
    update() {
      this.doUpdate();
    },
    async doUpdate(): Promise<void> {
      if (!this.objectContainsData(this.updatedFields)) {
        return;
      }
      const status = this.editing?.status;
      if (status !== "Not Submitted") {
        return;
      }
      try {
        this.loading = true;
        if (!this.uploadedFile.length) {
          return await this.sendData();
        }
        let response = await this.createAtlasfile(
          this.createFormData(this.uploadedFile as any)
        );
        this.mapFileData(response, this.editing);
        await this.sendData();
        // eslint-disable-next-line prettier/prettier
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.$emit("scrollTop");
      }
    },
    async doUpdateFile(): Promise<void> {
      const status = this.editing?.status;
      if (status !== "Not Submitted") {
        return;
      }
      try {
        this.loading = true;
        let response = await this.createAtlasfile(
          this.createFormData(this.uploadedFile as any)
        );
        this.mapFileData(response, this.editing);
        await this.sendData();
        this.hasUploadedFile = true;
        // eslint-disable-next-line prettier/prettier
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.$emit("scrollTop");
      }
    },
    changeAddressProperties(agencyAddress: IAgencyAddress): IAddress {
      let newAgencyAddressProperties: IAddress = {
        unitNumber: undefined,
        streetName: "",
        houseNumber: 0,
        streetDirection: "",
        streetType: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        line2: "",
        county: ""
      };
      newAgencyAddressProperties.city = agencyAddress.agencyCity;
      newAgencyAddressProperties.zipCode = agencyAddress.agencyZipCode;
      newAgencyAddressProperties.state = agencyAddress.agencyState;

      return newAgencyAddressProperties;
    },
    objectContainsData(data: {}): boolean {
      return Object.keys(data).length > 0;
    },

    onToolbarItemSelected(action: string): void {
      switch (action) {
        case "save":
          this.update();
          break;
        case "print":
          this.printCallback(
            this.editing,
            "billingChangeRequest/printBillingChangeRequest"
          );
          break;
        case "delete":
          this.deleteCallback();
          break;
        case "submit":
          this.Submit();
          break;
        case "cancel":
          this.goBackToListView("/billingchangerequests");
          break;
      }
    }
  },
  computed: {
    ...billingChangeRequestMapState(["makingApiRequest", "editing"]),
    ...billingChangeRequestMapGetters(["getBillingChangeRequestById"]),
    ...propertyChangeRequestMapState({
      loadingInsuredInfo: "makingApiRequest"
    }),
    original(): any {
      const res = this.getBillingChangeRequestById(this.billingChangeRequestId);
      return res;
    },
    updatedFields(): any {
      const toIgnore = ["data.agentInfo"];
      const allowedEmpty = [
        "data.insured.insuredAddress.unitNumber",
        "data.insured.insuredAddress.streetName",
        "data.insured.insuredAddress.city",
        "data.insured.insuredAddress.state",
        "data.insured.insuredAddress.zipCode",
        "data.insured.insuredAddress.country",
        "data.insured.insuredAddress.streetDirection",
        "data.insured.insuredAddress.houseNumber",
        "data.insured.insuredAddress.streetType",
        "data.insured.insuredAddress.county"
      ];
      return omit(
        this.editing && this.original
          ? objectDifference(
              cloneDeep(this.editing),
              cloneDeep(this.original),
              allowedEmpty
            )
          : {},
        toIgnore
      );
    },
    disableSubmitButton(): boolean {
      const insuredAddress = this.editing?.data.insured.insuredAddress;
      return (
        this.checkForEmptyRequiredFields ||
        this.policyIsLapsedAndHasNoAttachments(this.editing) ||
        !hasValidAddress(
          insuredAddress || defaultAddressStructure,
          insuredAddress?.isPOBoXFormat
        )
      );
    },
    primaryToolbar(): any {
      if (this.editing && this.editing.status === "Not Submitted") {
        return {
          text: "Submit",
          key: "submit",
          loading: this.makingApiRequest,
          disabled: this.disableSubmitButton
        };
      }
      return undefined;
    },
    secondaryToolbar(): any {
      if (this.editing) {
        const defaultActions = {
          text: "Action",
          key: "actions",
          subItems: [
            {
              title: "Print",
              command: "print",
              loading: this.makingApiRequest
            },
            {
              title: "Delete",
              command: "delete"
            }
          ]
        };
        if (this.editing.status === "Submitted") {
          return defaultActions;
        } else {
          defaultActions.subItems.push({
            title: "Cancel",
            command: "cancel",
            loading: this.makingApiRequest
          });
          return defaultActions;
        }
      }
      return undefined;
    },
    checkForEmptyRequiredFields(): boolean {
      if (this.editing && this.editing.data && this.editing.data.billing) {
        const billing = get(this.editing.data, "billing", {});
        return checkObjectForFalsyValues(billing, "agentCertify") ||
          (this.CancellationReasonRadioButton === "other" && !this.otherRemarks)
          ? true
          : false;
      }
      return true;
    },
    queryOverride(): any {
      return {
        "data.cancellationChangeRequestId": this.editing?._id,
        activityType: "CancellationChangeRequestActivity"
      };
    }
  },
  watch: {
    updatedFields: {
      handler(fields) {
        if (Object.keys(fields).length && !fields.attachments) {
          this.$nextTick().then(() => {
            this.update();
          });
        }
      },
      deep: true
    }
  }
});
